
export const CLIENT_LOGIN= "clientLogin";
export const CORPORATE_LOGIN= "corporateLogin";
export const PROVIDER_LOGIN = "providerLogin";

const USER_TYPE_MAP = {
    [CLIENT_LOGIN]: CLIENT_LOGIN,
    [CORPORATE_LOGIN]: CORPORATE_LOGIN,
    [PROVIDER_LOGIN]: PROVIDER_LOGIN
}

export default USER_TYPE_MAP;