export default [
    { 
        name:'Mag Wheel',
        translation : {
            key: "website.SpecialityService.Magwheels"
        }
    },
    { 
        name:'Car Alarms',
        translation : {
            key: "website.SpecialityService.CarAlarms"
        }
    },
    { 
        name:'Painting',
        translation : {
            key: "website.SpecialityService.Painting"
        }
    },
    { 
        name:'Automatic Starters',
        translation : {
            key: "website.SpecialityService.Automaticstarters"
        }
    },
    { 
        name:'Handicapped Service',
        translation : {
            key: "website.SpecialityService.Handicappedservice"
        }
    },
    { 
        name:'Custom Work',
        translation : {
            key: "website.SpecialityService.Customwork"
        }
    },
    { 
        name:'Window Tinting',
        translation : {
            key: "website.SpecialityService.Windowtinting"
        }
    },
    { 
        name:'Recycling',
        translation : {
            key: "website.SpecialityService.Recycling"
        }
    },
    { 
        name:'Unlock doors',
        translation : {
            key: "website.SpecialityService.Unlockdoors"
        }
    },
    { 
        name:'Battery boosts',
        translation : {
            key: "website.SpecialityService.Batteryboosts"
        }
    },
    { 
        name:'Tire Repair',
        translation : {
            key: "website.SpecialityService.Tirerepair"
    }}
]