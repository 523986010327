export default [
    {
        name:'Racing',
        translation: {
            key: "website.vehicletype.Racing"
        } 
    },
    {
        name:'Hybrid',
        translation: {
            key: "website.vehicletype.Hybrid"
        } 
    },
    {
        name:'Diesel',
        translation: {
            key: "website.vehicletype.Diesel"
        } 
    },
    {
        name:'European Cars',
        translation: {
            key: "website.vehicletype.Europeancars"
        } 
    },
    {
        name:'Electric',
        translation: {
            key: "website.vehicletype.Electric"
        } 
    },
    {
        name:'Trucks | Vans',
        translation: {
            key: "website.vehicletype.TrucksVans"
        } 
    },
    {
        name:'Auto Trans',
        translation: {
            key: "website.vehicletype.AutoTrans"
        } 
    },
    {
        name:'Manual Trans',
        translation: {
            key: "website.vehicletype.ManualTrans"
        } 
    },
    {
        name:'American Cars',
        translation: {
            key: "website.vehicletype.AmericanCars"
        } 
    },
    {
        name:'Asian Cars',
        translation: {
            key: "website.vehicletype.AsianCars"
        } 
    },
    {
        name:'Other',
        translation: {
            key: "website.vehicletype.Other"
        }
     }
]