import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, addDoc, serverTimestamp, collection } from 'firebase/firestore';
import { isDevelopment } from "./config";
import USER_TYPE_MAP from "../app/constant/userType";
const firebaseConfig = {
  apiKey: "AIzaSyDhNkJEcXAD7_sQvR_QgLHfprx3hhvH2f8",
  authDomain: "autowiz-app.firebaseapp.com",
  projectId: "autowiz-app",
  storageBucket: "autowiz-app.appspot.com",
  messagingSenderId: "65558141642",
  appId: "1:65558141642:web:822e457daae8c45fd73233",
  measurementId: "G-EJNRD64YXN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const _logEvent = (error, error2) => {
  try {
    const options = isDevelopment  ? { 'debug_mode':true } : {};
    logEvent(analytics, error, error2, options);
    let user = {};
    try {
      const userType = localStorage.userType || Object.keys(localStorage).filter(item=> USER_TYPE_MAP[item])[0];
      const userAsString = localStorage[userType];
      user = JSON.parse(userAsString || "{}");
    } catch (error) {
      user = {
        error
      }
    }
    addDoc(collection(db, "errors"), {
      ...error2, 
      timestamp: serverTimestamp(),
      user,
      pageUrl: window.location.href
    });
    
  } catch (error) {
    console.warn("Failed to log"); 
  }
}


export { _logEvent as logEvent };