export default [
    { 
        name:'Oil Changes',
        translation: {
            key: "website.PartsOffered.OilChanges"
        }
    },
    { 
        name:'Filters',
        translation: {
            key: "website.PartsOffered.Filters"
        }
    },
    { 
        name:'Belts',
        translation: {
            key: "website.PartsOffered.Belts"
        }
    },
    { 
        name:'Hoses',
        translation: {
            key: "website.PartsOffered.Hoses"
        }
    },
    { 
        name:'Tires',
        translation: {
            key: "website.PartsOffered.Tires"
        }
    },
    { 
        name:'Battery',
        translation: {
            key: "website.PartsOffered.Battery"
        }
    },
    { 
        name:'Shocks',
        translation: {
            key: "website.PartsOffered.Shocks"
        }
    },
    { 
        name:'Lubricants',
        translation: {
            key: "website.PartsOffered.Lubricants"
        }
    },
    { 
        name:'Wipers',
        translation: {
            key: "website.PartsOffered.Wipers"
        }
    },
    { 
        name:'Antifreeze',
        translation: {
            key: "website.PartsOffered.Antifreeze"
        }
    },
    { 
        name:'Heating',
        translation: {
            key: "website.PartsOffered.Heating"
        }
    },
    { 
        name:'Auto cleaning products',
        translation: {
            key: "website.PartsOffered.Autocleaningproducts"
        }
    },
    { 
        name:'Engine Parts',
        translation: {
            key: "website.PartsOffered.EngineParts"
        }
    },
    { 
        name:'Spark Plugs',
        translation: {
            key: "website.PartsOffered.SparkPlugs"
        }
    },
    { 
        name:'Air Con / Heating',
        translation: {
            key: "website.PartsOffered.AirConHeating"
        }
    },
    { 
        name:'Electricals',
        translation: {
            key: "website.PartsOffered.Electricals"
        }
    },
    { 
        name:'Brake Pads',
        translation: {
            key: "website.PartsOffered.BrakePads"
        }
    },
    { 
        name:'Rotors',
        translation: {
            key: "website.PartsOffered.Rotors"
        }
    },
    { 
        name:'Wipers Module',
        translation: {
            key: "website.PartsOffered.WipersModule"
        }
    },
    { 
        name:'Calipers',
        translation: {
            key: "website.PartsOffered.Calipers"
        }
    },
    { 
        name:'Lights and Bulbs',
        translation: {
            key: "website.PartsOffered.LightsandBulbs"
        }
    },
    { 
        name:'Auto Body parts',
        translation: {
            key: "website.PartsOffered.AutoBodyparts"
        }
    },
    { 
        name:'Paints',
        translation: {
            key: "website.PartsOffered.Paints"
        }
    },
    { 
        name:'Starters and Alternators',
        translation: {
            key: "website.PartsOffered.StartersandAlternators"
        }
    },
    { 
        name:'Roof Racks and Carriers',
        translation: {
            key: "website.PartsOffered.RoofRacksandCarriers"
        }
    },
    { 
        name:'Auto Covers',
        translation: {
            key: "website.PartsOffered.AutoCovers"
        }
    },
    { 
        name:'Exhaust Systems',
        translation: {
            key: "website.PartsOffered.ExhaustSystems"
        }
    }
]