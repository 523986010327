import {  Navigate  } from 'react-router-dom'
import { isProviderLoggedIn } from './utils';
import { PROVIDER_LOGIN } from './app/constant/userType';

export default function PrivateProviderRoute({ component: Component, ...rest }) {

    let isLogged = false;
    const checkAuth = () => {
        return isProviderLoggedIn(PROVIDER_LOGIN);
    }

    if(checkAuth()) {
        isLogged = true;
        localStorage.setItem("userType", PROVIDER_LOGIN);
    }
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isLogged ? <Component /> : <Navigate to="/" />;
}