import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './redux/store'
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import "video-react/dist/video-react.css";
import './i18n/config';
import "./config/firebase";
import ErrorBoundary from './app/common/ErrorBoundary';

ReactDOM.render(
  <ErrorBoundary>
    <Provider store = {store}>
      <App />
    </Provider>
  </ErrorBoundary>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
