import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../website/header";
import Footer from "../../website/footer";
import './styles.css'
import { url } from "../../../config/config";

function VerifyEmail() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const [{ isLoading, isVerified }, setPageConfig] = React.useState({
    isVerified: false,
    isLoading: true,
  });

  React.useEffect(() => {
    (async function () {
      if (!token) return;
      // /api/verify-email
      try {
        const { data } = await axios.post(`${url}verify-email`, { token });
        if (data.code === 200) {
          setPageConfig((prev) => ({
            ...prev,
            isVerified: true,
            isLoading: false,
          }));
          localStorage.clear();
          setTimeout(()=>{
            navigate("/", {replace: true});
          }, 10000);
          return toast.success("Email verified.");
        }
      } catch (error) {}
      toast.error("Unable to verify email.");
      setPageConfig((prev) => ({
        ...prev,
        isVerified: false,
        isLoading: false,
      }));
    })();
  }, []);
  return (
    <>
      <Header />
      <ToastContainer />
      <div className="verify-page-container" >
        {
            token ?
        <div className="verify-page-result">
            {isLoading
            ? "Please wait while we are verifying your account."
            : isVerified
            ? <div>Great!, your account is verified. Please continue to <a href='/' >login</a> into your account</div>
            : "Sorry but we are unable to verify your account. Please contact our support for further action."}
        </div>
        :
        <div className="verify-page-error">Invalid email. Please connect with support for further assistance.</div>
        }
       </div>
      <Footer />
    </>
  );
}
export default VerifyEmail;
