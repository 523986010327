export default [
    { 
        name: 'Oil Changes',
        translation: {
            key: "website.repairType.OilChanges"
        }
    },
    { 
        name: 'Diagnostic',
        translation: {
            key: "website.repairType.Diagnostic"
        }
    },
    { 
        name: 'Air Con / Heating',
        translation: {
            key: "website.repairType.AirConHeating"
        }
    },
    { 
        name: 'Transmission',
        translation: {
            key: "website.repairType.Transmission"
        }},
    { 
        name: 'Brakes',
        translation: {
            key: "website.repairType.Brakes"
        }},
    { 
        name: 'Glass Replacement',
        translation: {
            key: "website.repairType.GlassReplacement"
        }},
    { 
        name: 'Suspension',
        translation: {
            key: "website.repairType.Suspension"
        }},
    { 
        name: 'Engine',
        translation: {
            key: "website.repairType.Engine"
        }},
    { 
        name: 'Pipes & Exhaust',
        translation: {
            key: "website.repairType.PipesExhaust"
        }},
    { 
        name: 'Electricals',
        translation: {
            key: "website.repairType.Electricals"
        }},
    { 
        name: 'Body Shop',
        translation: {
            key: "website.repairType.BodyShop"
        }},
    { 
        name: 'Wheel Alignment',
        translation: {
            key: "website.repairType.WheelAlignment"
        }},
    { 
        name: 'Tire Changes',
        translation: {
            key: "website.repairType.TireChanges"
        }},
    { 
        name: 'Tank Replacement',
        translation: {
            key: "website.repairType.TankReplacement"
        }},
    { 
        name: 'Battery Changes',
        translation: {
            key: "website.repairType.BatteryChanges"
        }},
    { 
        name: 'Hoses & Belts',
        translation: {
            key: "website.repairType.HosesBelts"
        }},
    { 
        name: 'Radiator',
        translation: {
            key: "website.repairType.Radiator"
        }},
    { 
        name: 'Wipers',
        translation: {
            key: "website.repairType.Wipers"
        }},
    { 
        name: 'Trailer Hitches',
        translation: {
            key: "website.repairType.TrailerHitches"
        }},
    { 
        name: 'Boost',
        translation: {
            key: "website.repairType.Boost"
        }},
    { 
        name: 'Door Locks',
        translation: {
            key: "website.repairType.DoorLocks"
        }},
    { 
        name: 'Gasoline',
        translation: {
            key: "website.repairType.Gasoline"
        }},
    { 
        name: 'Towing',
        translation: {
            key: "website.repairType.Towing"
        }},
    { 
        name: 'Steering',
        translation: {
            key: "website.repairType.Steering"
        }},
    { 
        name: 'Car Wash',
        translation: {
            key: "website.repairType.CarWash"
        }},
    { 
        name: 'Rust Proofing',
        translation: {
            key: "website.repairType.Rustproofing"
        }},
]