export default [
    { 
        name:'Shuttle',
        translation : {
        key: "website.nearService.Shuttle"
    } },
    { 
        name:'Television',
        translation : {
        key: "website.nearService.Television"
    } },
    { 
        name:'Auto Loaner',
        translation : {
        key: "website.nearService.AutoLoaner"
    } },
    { 
        name:'Children Area',
        translation : {
        key: "website.nearService.Childrenarea"
    } },
    { 
        name:'Drinks',
        translation : {
        key: "website.nearService.Drinks"
    } },
    { 
        name:'Public Transmit',
        translation : {
        key: "website.nearService.Publictransit"
    } },
    { 
        name:'Near Restaurant',
        translation : {
        key: "website.nearService.Nearrestaurant"
    } },
    { 
        name:'Open Weekends',
        translation : {
        key: "website.nearService.OpenWeekends"
    } },
    { 
        name:'Multiple Bays 3+'
        ,translation : {
        key: "website.nearService.Multiplebays"
    } },
    { 
        name:'Waiting Area',
        translation : {
        key: "website.nearService.Waitingarea"
    } },
    { 
        name:'Open Evenings',
        translation : {
        key: "website.nearService.Openevenings"
    } },
    { 
        name:'Safe Area',
        translation : {
        key: "website.nearService.Safearea"
    } },
    { 
        name:'Franchise',
        translation : {
        key: "website.nearService.Franchise"
    } },
    { 
        name:'Public washroom',
        translation : {
        key: "website.nearService.Publicwashroom"
    } }
]